export default {
  global: {
    agreementsTopic: 'ESHOP',
    city: 'Roma',
    country: 'Italia',
    langIso: 'it-IT',
    countryIso: 'ITA',
    xTenant: 'IT',
    storeSpecificXTenant: 'IT',
    loqateApiKey: 'EU83-CH59-RF63-AT72',
    pagination: {
      productsPerPage: 24,
    },
    pharmacyTypes: [],
    phoneCode: '+39',
    phoneCodes: ['+39'],
    phoneValidations: ['^([3]{1})(\\d{7,11})$'],
    storeName: 'Dr.Max',
    validationPhone: '^([3]{1})(\\d{7,11})$',
    zipLength: 5,

    /**
     * Global features - used across multiple common modules
     */
    advancedAgreementsEnabled: true,
    billingInformationAddressEnabled: false,
    birthDateOverEnabled: 18,
    companyBillingAddressEnabled: true,
    competitionServiceTimeBasedEnabled: false,
    crnEnabled: false,
    fiscalCodeEnabled: true,
    loggedPriceInfoEnabled: true,
    loyaltyCardEnabled: true,
    loyaltyCardPlusPrefixEnabled: true,
    loyaltySavingsEnabled: false,
    multiStoreEnabled: false,
    myAccountCouponsEnabled: false,
    myAccountAmazonEnabled: true, // todo: move to my-account module
    myAccountAmazonFromPlacementApi: true, // todo: move to my-account module
    myAccountAmazonAnotherOffers: false, // todo: move to my-account module
    myAccountOtherOffersButtonEnabled: false, // todo: move to my-account module
    newUrlResolverEnabled: true,
    otcFormEnabled: false,
    phoneCodeEnabled: true,
    phoneCodeSelectEnabled: false,
    phoneCodeSentSeparatelyEnabled: true,
    regionSelectEnabled: true,
    savingsAndBenefitsInHeaderEnabled: false,
    skDicEnabled: false,
    splitStreetNumberBeforeSavingEnabled: true,
    blockItemRelatedWarnings: false,
    streetNumberEnabled: false,
    vatIdEnabled: true,
    vatIdRequired: true,

    /**
     * Google Maps API
     * API Keys: https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/1600290889/API+keys+-+Google+Maps
     * Supported languages: Subset of ISO 639-1, see https://developers.google.com/maps/faq#languagesupport
     */
    maps: {
      apiKey: 'AIzaSyC1d8yO-s6JG95rQcsWQbdAr3eGV5c4Wxc',
      interfaceLanguage: 'it',
      myLocationIcon: 'map-my-location-sk.png',
      defaultLocation: {
        latitude: 43.045046,
        longitude: 12.378992,
      },
    },

    watchdog: {
      stockAlertEnabled: true,
      priceAlertEnabled: true,
    },
  },
}
