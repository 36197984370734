import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.trim.js";
export default defineAppConfig({
  validations: {
    cityAllowedCharacters: function cityAllowedCharacters(value) {
      return /^[a-zA-Z "'\s\u00C0-\u024F]*$/.test(value);
    },
    cityContainsLetter: function cityContainsLetter() {
      return true;
    },
    cityMaxLength: 30,
    companyNameMaxLength: 70,
    crnNumbersOnly: function crnNumbersOnly() {
      return true;
    },
    crnValid: function crnValid() {
      return true;
    },
    customerNotesAllowedCharacters: function customerNotesAllowedCharacters() {
      return true;
    },
    emailMaxLength: 50,
    firstLastNameAllowedCharacters: function firstLastNameAllowedCharacters(value) {
      return /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value);
    },
    firstNameMaxLength: 35,
    forbiddenWords: [],
    lastNameMaxLength: 35,
    passwordForbiddenKeywords: ["parola"],
    postCodeValid: function postCodeValid(value) {
      return /^[0-9]{5}$/.test(value);
    },
    postCodeValidByForbiddenCode: function postCodeValidByForbiddenCode(value) {
      var forbiddenZIPCodes = ["47890", "47891", "47892", "47893", "47894", "47895", "47896", "47897", "47898", "47899", "00120", "23041", "22061"];
      var isForbiddenCode = forbiddenZIPCodes.includes(value.trim());
      return !isForbiddenCode;
    },
    postCodeValidByThreeDigits: function postCodeValidByThreeDigits(value) {
      var allowedFirstThreeDigits = ["000", "001", "010", "011", "020", "021", "030", "031", "040", "041", "050", "051", "060", "061", "070", "071", "080", "081", "090", "091", "100", "101", "110", "111", "120", "121", "130", "131", "138", "139", "140", "141", "150", "151", "160", "161", "170", "171", "180", "181", "190", "191", "200", "201", "208", "209", "210", "211", "220", "221", "230", "231", "238", "239", "240", "241", "250", "251", "260", "261", "268", "269", "270", "271", "280", "281", "288", "289", "290", "291", "300", "301", "310", "311", "320", "321", "330", "331", "340", "341", "350", "351", "360", "361", "370", "371", "380", "381", "390", "391", "400", "401", "410", "411", "420", "421", "430", "431", "440", "441", "450", "451", "460", "461", "470", "471", "475", "478", "479", "480", "481", "500", "501", "510", "511", "520", "521", "530", "531", "540", "541", "550", "551", "560", "561", "570", "571", "580", "581", "590", "591", "600", "601", "610", "611", "620", "621", "630", "631", "638", "639", "640", "641", "650", "651", "660", "661", "670", "671", "700", "701", "710", "711", "720", "721", "730", "731", "740", "741", "750", "751", "760", "761", "800", "801", "810", "811", "820", "821", "830", "831", "840", "841", "850", "851", "860", "861", "870", "871", "880", "881", "888", "889", "890", "891", "898", "899", "900", "901", "910", "911", "920", "921", "930", "931", "940", "941", "950", "951", "960", "961", "970", "971", "980", "981"];
      var firstThreeDigits = value.trim().substring(0, 3);
      return allowedFirstThreeDigits.includes(firstThreeDigits);
    },
    streetAllowedCharacters: function streetAllowedCharacters(value) {
      return /^(?!.*[;*@#&$!{}?~^|])[^;*@#&$!{}?~^|]*\d[^;*@#&$!{}?~^|]*$/.test(value);
    },
    streetMaxLength: 40,
    streetNumberContainsNumber: function streetNumberContainsNumber() {
      return true;
    },
    streetNumberValid: function streetNumberValid() {
      return true;
    },
    streetValid: function streetValid(value) {
      return /^[^0-9]+\s.?[0-9]+.*$/.test(value);
    },
    vatIdForbiddenValues: [],
    vatIdForbiddenWords: function vatIdForbiddenWords() {
      return true;
    },
    vatIdLength: 13,
    vatIdStartsWith: function vatIdStartsWith(value) {
      return value.substring(0, 2) === "IT";
    },
    vatIdValid: function vatIdValid(value) {
      return /^IT[0-9]{11}$/.test(value);
    }
  }
});