export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: true,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: true,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: true,

    /*eslint-disable */
    favoriteFilterCategoriesIds: [6027, 6053, 6099, 6174, 6229, 6272, 6301, 6313],

    favoritePharmaciesEnabled: true,
    favoriteProductsEnabled: true,
    favoritesV2Enabled: false,

    generalTermsTextEnabled: false,

    returnHygienicAgreementEnabled: false,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-green.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: false,
    loyaltyCardNumberDigits: 9,
    loyaltyCardNumberStaticPrefix: '++',
    loyaltyProgramBenefitsCount: 3,
    myAccountSorpFromCustomerServiceEnabled: false,
    standAloneCardModeEnabled: false,
    mktPpdUnified: true,
    zipCodeRequired: true,
    checkPersonalDetailsCityFieldEnabled: false,
    accountInfoSorpUpgradeEnabled: false,

    phoneNumberRequired: true,
    phoneNumberSorpCheckEnabled: false,
    phoneCodeEnabled: true,

    priceEnabled: true,
    priceSummaryEnabled: false,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    returnAndComplaintTitleSplitEnabled: false,
    returnIbanFieldEnabled: false,
    showNotificationSettings: true,

    sexEnabled: false,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: true,
    typingInLoginEmailEnabled: true,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
    emailWhisperer: {
      enabled: true,
      topLevelDomains: ['com', 'cz', 'sk', 'pl', 'it', 'org', 'ro'],
      domains: ['libero.it', 'virgilio.it', 'tiscali.it', 'alice.it', 'hotmail.it', 'posta.it', 'fastwebnet.it', 'aol.it', 'outlook.com', 'gmail.com', 'live.com', 'yahoo.it'],
      secondLevelDomains: [],
    }
  },
}
