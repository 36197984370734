const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'pickupSelect.config.inPostPok.pickupInstructionsShippingText1' },
      {
        text: 'pickupSelect.config.inPostPok.pickupInstructionsShippingText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      { text: 'pickupSelect.config.inPostPok.pickupInstructionsSmsText1' },
      {
        text: 'pickupSelect.config.inPostPok.pickupInstructionsSmsText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'availability-box',
    fragments: [
      { text: 'pickupSelect.config.inPostPok.pickupInstructionsAvailabilityBoxText1' },
      {
        text: 'pickupSelect.config.inPostPok.pickupInstructionsAvailabilityBoxText2',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: '',
  reservationLabel: '',
  rxReservationLabel: '',
  unavailablePickupLabel: 'pickupSelect.config.inPostPok.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.inPostPok.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.inPostPok.confirmButtonLabel',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-inpost-pok.svg',
    disabled: 'pin-inpost-pok-disabled.svg',
    selected: 'pin-inpost-pok-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#FEC909',
  icon: '/img/delivery/inpost.svg',
}
