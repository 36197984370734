export const modules = [
  '@nsf/i18n',
  '@nsf/base',
  '@nsf/algolia',
  '@nsf/search',
  '@nsf/articles',
  '@nsf/article-preview',
  '@nsf/apotheka-web',
  '@nsf/apotheka-widget',
  '@nsf/pickup-select-v2',
  '@nsf/brands',
  '@nsf/checkout',
  '@nsf/catalog',
  '@nsf/gtm',
  '@nsf/delivery-fe',
  '@nsf/cms',
]

export default { modules }
