import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import footer from './footer.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import shipmentInfo from './shipmentInfo.mjs'
import inputTypes from './inputTypes.mjs'
import icons from './icons.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...footer,
  ...termsPages,
  ...paymentCodes,
  ...shipmentInfo,
  ...inputTypes,
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'HEUREKA',
      topic: 'HEUREKA',
      value: 'N',
    },
    {
      channel: 'PRICE_COMPARISONS',
      topic: agreementsTopic,
      value: 'N',
    },
  ],
  firstPartySellerId: '0',
  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'general',
  shippingInfoBlocks: '',
  stockStatusChunkSize: 50,
  thankYouText: {
    payment: 'SMS',
    dispatch: 'SMS',
    useBoldPartVariant: true,
  },
  ...config,
  ...icons,
})
